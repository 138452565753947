import React from "react";

// markup text 
// - lista
// ** bold **

export const renderTextWithFormatting = (text) => {
  // Dividi il testo in linee
  const lines = text.split('\\n');
  const finalOutput = [];

  let currentList = [];

  lines.forEach((line, index) => {
    if (line.trim().startsWith('-')) {
      // Rimuovi '-' e formatta il resto della linea
      let content = line.trim().substring(1).trim();
      let parts = content.split('**');

      // Aggiungi alla lista di elementi <li>
      currentList.push(
        <li key={index}>
          {parts.map((part, partIndex) => (
            partIndex % 2 !== 0 ? <strong key={partIndex}>{part}</strong> : part
          ))}
        </li>
      );
    } else {
      // Se abbiamo una lista aperta e incontriamo una linea non-lista
      if (currentList.length > 0) {
        // Termina la lista corrente e la aggiunge all'output
        finalOutput.push(<ul key={`ul-${index}`}>{currentList}</ul>);
        currentList = []; // Reset della lista
      }

      // Aggiungi la linea corrente come <p>
      let parts = line.split('**');
      finalOutput.push(
        <p className="gr-text-9 text-color-opacity" key={index}>
          {parts.map((part, partIndex) => (
            partIndex % 2 !== 0 ? <strong key={partIndex}>{part}</strong> : part
          ))}
        </p>
      );
    }
  });
  // Al termine dell'iterazione, se c'è una lista non ancora aggiunta
  if (currentList.length > 0) {
    finalOutput.push(<ul key="last-ul">{currentList}</ul>);
  }

  return finalOutput;
};

